import React from 'react-dom'
import styles from './footer.module.scss'
import Link from 'next/link'
import { gql, useQuery } from '@apollo/client'
import { useStore } from 'hooks/locationContext'
import getUri from 'lib/getUri'
import { reportError } from 'utils/monitoring'
import LoadingUI from 'components/loading'

export default function BottomFooter(): JSX.Element {
  const store = useStore()[0]
  const currentLocation = useStore()[0]
  const { region } = currentLocation.initialLocation
  const getFooter = gql`
        query getBottomFooter($siteHandle: [String] = "${region || 'vic'}"){
            globalSet(site: $siteHandle){
              ...on copyright_GlobalSet{
                header
                licensesArea{
                  ...on licensesArea_item_BlockType{
                    text
                    licenseLink
                  }
                }
                subheader
                legacyArea{
                  ...on legacyArea_item_BlockType{
                    text
                    licenseLink
                    licenseEntry{
                      slug
                    }
                  }
                }
              }
            }
        }
`

  const { loading, error, data } = useQuery<any>(getFooter, {
    variables: {
      siteHandle: region || 'vic',
    },
  })

  if (loading) {
    return <LoadingUI />
  }
  if (error) {
    return reportError(error) as any
  }

  let License = []
  let Links = []

  if (data) {
    License = data?.globalSet.licensesArea?.map((link: any, index: any) => (
      <li className="yellow-vertical-line" key={index}>
        <span>{link.text}</span>
      </li>
    ))
    Links = data?.globalSet.legacyArea?.map((link: any, index: any) => (
      <li key={index}>
        {link?.licenseEntry?.[0]?.slug ? (
          <Link href={`${getUri(store.initialLocation)}/${link?.licenseEntry?.[0]?.slug}`}>
            <a>{link.text}</a>
          </Link>
        ) : (
          <Link href={link?.licenseLink}>
            <a>{link.text}</a>
          </Link>
        )}
      </li>
    ))
  }

  return (
    <div className={styles['footer']}>
      <div className="container">
        <div className={styles['footer__top']}>
          <div className={styles['footer__top-copyright']}>
            <span className={styles['footer__top-copyright-first']}>{data.globalSet.header}</span>
          </div>
          <ul>{License}</ul>
        </div>
        <div className={styles['footer__bottom']}>
          <span>{data.globalSet.subheader}</span>
          <ul>{Links}</ul>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useQuery } from '@apollo/client'
import { SITE_REGION_MAP } from 'lib/config'
import { useStore } from 'hooks/locationContext'
import CloseCross from 'images/svg/close-cross.svg'
import styles from './pop-up.module.scss'
import { Location_Location_Entry } from 'lib/generated/graphql-types'
import { locationEntries as locationEntriesQuery } from 'gql/locationEntries.gql'

interface LocationEntries {
  entries: Location_Location_Entry[] | null
}

const isEmptyValue = (val: any) => {
  return val === undefined || val === null || val === ''
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PopUp({ closePopUp }: any) {
  const currentLocation = useStore()[0]
  const { region, location } = currentLocation.initialLocation
  // console.log('>>> PopUp.initialLocation', region, location)
  const [hasLocation, setHasLocation] = useState(false)

  // get, filter and sort regions/locations
  const { data } = useQuery<LocationEntries>(locationEntriesQuery)
  const entries = data?.entries as Location_Location_Entry[]
  const locations = [...entries].sort(function (a, b) {
    return a.siteId - b.siteId
  })
  let qldAndNsw = false
  const regions = locations.reduce((accum, l) => {
    if (!accum.find((r) => r.siteId === l.siteId)) {
      accum.push({
        siteId: l.siteId,
        siteHandle: l.siteHandle,
      })
    }
    if (l.siteHandle === 'qld' && l.title.toLowerCase().includes('nsw')) {
      qldAndNsw = true
    }
    return accum
  }, []) as any

  // Use context region to set active tab
  const currentRegionId = regions.find((r: any) => r.siteHandle === region).siteId
  const [activeTab, changeActiveTab] = useState(currentRegionId)

  const router = useRouter()
  const utmContainer: any = {}
  const acceptableParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_agid', 'device', 'placement', 'gclid']
  for (const key in router.query) {
    if (acceptableParams.includes(key)) {
      utmContainer[key] = router.query[key]
    }
  }
  const urlParams = queryString.stringify(utmContainer)

  useEffect(() => {
    setHasLocation(!(location === undefined || location === null || location === ''))
    // if (typeof window !== 'undefined') {
    //   const cookieLoc = localStorage.getItem('selectedLocation')
    //   const cookieRegion = localStorage.getItem('selectedRegion')
    //   if (isEmptyValue(location) && !isEmptyValue(cookieLoc) && !isEmptyValue(cookieLoc)) {
    //     const regionalUrl = `/${cookieRegion}/${cookieLoc}${urlParams ? '?' + urlParams : ''}`
    //     // console.log('>>> ROUTER PUSH', regionalUrl)
    //     // router.push(regionalUrl);
    //   }
    // }
  }, [region, location, urlParams])

  const handleLocation = (
    e: any,
    region: any,
    location: any
  ) => {
    e.preventDefault()
    localStorage.setItem('selectedRegion', region)
    localStorage.setItem('selectedLocation', location)

    router.push(`/${region}/${location}${urlParams ? '?' + urlParams : ''}`)

    closePopUp(false)
    enableScroll()
  }

  function enableScroll() {
    document.body.style.overflow = 'initial'
  }

  return (
    <div className={styles['top-header__pop-up'] + ' --pop-up-js'}>
      <div className={styles['top-header__close']}>
        <div
          className={styles['close-wrapper']}
          onClick={() => {
            if (!hasLocation) return

            closePopUp(false)
            enableScroll()
          }}
        >
          <CloseCross />
        </div>
      </div>

      <div className={styles['top-header__pop-up-title']}>Where would you like to build?</div>

      <ul className={styles['top-header__pop-up-top-list']}>
        {regions.map(({ siteId, siteHandle }: any) => (
          <li className={activeTab === siteId ? '--active-region' : ''} key={siteId} onClick={() => changeActiveTab(siteId)}>
            {siteHandle === 'qld' && qldAndNsw ? 'QLD+NSW' : siteHandle}
          </li>
        ))}
      </ul>

      <div className={styles['top-header__pop-up-yellow-block']}>
        {locations.map((reg) => {
          if (reg.siteId === activeTab) {
            return (
              <div key={reg.siteId + reg.title} className={styles['top-header__pop-up-yellow-block-top']}>
                <div className={styles['top-header__pop-up-yellow-block-title']}>{reg.title}</div>

                {reg.children.map((loc: any) => {
                  return (
                    <div key={`${(SITE_REGION_MAP as any)[reg.siteId]}/${loc.slug}`} className={styles['top-header__pop-up-yellow-button-wrap']}>
                      <a
                        className={
                          styles['top-header__pop-up-yellow-button-wrap-link'] +
                          ' black-button' +
                          `${loc.slug === location ? ' --active-region' : ''}`
                        }
                        onClick={(e) => {
                          handleLocation(e, reg.siteHandle, loc.slug)
                        }}
                      >
                        {loc.title}
                      </a>
                      <a
                        className={styles['top-header__pop-up-yellow-button-wrap-text']}
                        onClick={(e) => {
                          handleLocation(e, reg.siteHandle, loc.slug)
                        }}
                      >
                        <span>
                          <span className="--only-mobile">{loc.title}</span> {loc.description}
                        </span>
                      </a>
                    </div>
                  )
                })}
              </div>
            )
          }
        })}
      </div>
      {/* <div className={styles['top-header__pop-up-covid']}>
        <span>COVID Message area</span>Lorem ipsum dolor sit amet, consectetur adqua.
      </div> */}
    </div>
  )
}

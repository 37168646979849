export class ProductsServiceStateModel {
  filter: any = {};

  allFilters: any = {
    homeDesign: [],
    estate: [],
    location: [],
    readyToBuild: [],
  };

  filteredProducts: any = [];

  allProducts: any = [];
  displayedProducts: any = [];
  displayedProductsLength = 8;
}

import React from 'react-dom'
import styles from './footer.module.scss'
import Link from 'next/link'
import { reportError } from 'utils/monitoring'
import LoadingUI from 'components/loading'
import getUri from 'lib/getUri'
import { gql, useQuery } from '@apollo/client'
import { useStore } from 'hooks/locationContext'
import { Footer_HeaderItems_Entry, Footer_HeaderUpItems_Entry } from 'lib/generated/graphql-types'

interface FooterEntries {
  entries: (Footer_HeaderItems_Entry | Footer_HeaderUpItems_Entry)[] | null
}

export default function MainFooter(): JSX.Element {
  const currentLocation = useStore()[0]
  const { region, location } = currentLocation.initialLocation

  const getFooter = gql`
    query getFooter($siteHandle: [String] = "${region || 'vic'}"){
      entries(site: $siteHandle, section: "footer"){
        ...on footer_headerUpItems_Entry{
          id
          typeHandle
          children{
            title
            ...on footer_upItem_Entry{
              title
              text
              buttonText
              buttonLink{
                url
              }
            }
          }
        }
        ...on footer_headerItems_Entry{
          id
          typeHandle
          children{
            title
            ...on footer_item_Entry{
              lightswitch
              internalLink{
                uri
              }
              externalLink
            }
          }
        }
      }
    }
  `
  const { loading, error, data } = useQuery<FooterEntries>(getFooter)
  const store = useStore()[0]

  const entries = data?.entries || []
  const topBlockEntries = entries.filter((entry: any) => entry?.typeHandle === 'headerUpItems') || []
  const topLeftBlockEntries = ((topBlockEntries as any)[0]?.children || [])[0]
  const topRightBlockEntries = ((topBlockEntries as any)[1]?.children || [])[0]

  const leftTopLinkUI =
    (topLeftBlockEntries?.buttonLink || [])[0]?.url?.trim()?.length && topLeftBlockEntries?.buttonText?.trim()?.length ? (
      <Link href={`/${region}/${location}/contact-us`}>
        <a className="yellow-button">
          <span>{topLeftBlockEntries?.buttonText}</span>
        </a>
      </Link>
    ) : (
      ''
    )

  const rightTopLinkUI =
    (topRightBlockEntries?.buttonLink || [])[0]?.url?.trim()?.length && topRightBlockEntries?.buttonText?.trim()?.length ? (
      <Link href={`/${region}/${location}/display-homes`}>
        <a className="yellow-button">
          <span>{topRightBlockEntries?.buttonText}</span>
        </a>
      </Link>
    ) : (
      ''
    )

  const bottomBlockEntries =
    entries.filter((entry: any) => entry?.typeHandle !== 'headerUpItems')?.filter((entry: any) => (entry?.children || []).length) || []

  const linksFromBlockUI = (entry: any) =>
    entry?.children?.map((child: any, index: any) => {
      const childClass = child?.lightswitch ? 'lightswitch' : ''
      const url = child?.internalLink?.length ? `${getUri(store.initialLocation)}/${child.internalLink[0].uri}` : child?.externalLink
      const isExternal = url && /^https?:\/\//.test(url)
      const showItem = url && child?.title

      return showItem ? (
        <li key={index} className={childClass}>
          <Link href={url || '/'}>
            <a target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined}>
              {child?.title || ''}
            </a>
          </Link>
        </li>
      ) : null
    })

  const bottomBlockUI = bottomBlockEntries?.map((entry, index) => (
    <ul className={styles['footer__bottom-link']} key={index}>
      {linksFromBlockUI(entry)}
    </ul>
  ))

  if (loading) return <LoadingUI />
  if (error) reportError(error)
  if (!data) return null

  return (
    <div className={styles['footer']}>
      <div className="container">
        <div className={styles['footer__wrap']}>
          <div className={styles['footer__top']}>
            <div className={styles['footer__top-left']}>
              <div className={styles['footer__top-left-wrap']}>
                <span className={styles['footer__top-left-title']}>{topLeftBlockEntries?.title || ''}</span>
                <span className={styles['footer__top-left-text']}>{topLeftBlockEntries?.text || ''}</span>
              </div>
              {leftTopLinkUI}
            </div>
            <div className={styles['footer__top-right']}>
              <div className={styles['footer__top-right-wrap']}>
                <span className={styles['footer__top-right-title']}>{topRightBlockEntries?.title || ''}</span>
                <span className={styles['footer__top-right-text']}>{topRightBlockEntries?.text || ''}</span>
              </div>
              {rightTopLinkUI}
            </div>
          </div>
          <div className={styles['footer__bottom']}>{bottomBlockUI}</div>
        </div>
      </div>
    </div>
  )
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import TopHeader from '../top-header'
import MainHeader from '../main-header'
import MainFooter from '../main-footer'
import BottomFooter from '../bottom-footer'
import LOCATIONS_BY_REGION from 'lib/locationsByRegion'
import { useStore } from 'hooks/locationContext'
import { gql, useQuery } from '@apollo/client'
import { NextSeo } from 'next-seo'
import { getImgixUrl } from 'lib/getImgixUrl'
// import getSeodata from 'lib/getSeodata'

const MainLayout = ({ children, title = 'ABCHOMES', activeTab, ...props }: any): JSX.Element => {
  // const [metaTitleSuffix, setMetaTitleSuffix] = useState<string>('')
  const redirectsQuery = gql`
    query redirects {
      globalSet(handle: "maintenance") {
        ... on maintenance_GlobalSet {
          maintenance
        }
      }
    }
  `
  const { data } = useQuery<any>(redirectsQuery)

  useEffect(() => {
    if (data?.globalSet?.maintenance) Router.push('/maintenance')
  }, [data])

  const currentLocation = useStore()[0]
  const { region = 'national' } = currentLocation.initialLocation
  const router = useRouter()
  const location = router?.query?.location

  if (router?.query?.slug === '[id]') {
    Router.push('/')
  }

  let slug = router.query.slug

  if (!router.query.id) {
    slug = ((router?.asPath || '').split('/') || [])[(router?.asPath || '').split('/').length - 1]
    if (slug === router?.query?.location) slug = 'home'
  }

  // OLD META CONSTANTS
  // const metaData = props.metaData
  // const seo = props.seo
  // const metaDataByLocation = metaData?.find((item: any) => item?.location?.some((loc: any) => loc?.slug === location))
  // const descRegexp = /<meta\s+name="description"\s+content="([^"]*)">/g
  // const descMatch = descRegexp.exec(seo?.metaTagContainer)
  // const seoDescription = descMatch ? descMatch[1] : null
  // const metaTags = seo?.metaTagContainer?.split('<') || []
  // const metaLinks = seo?.metaLinkContainer?.split('<') || []
  // const canonicalRegex = /<link\s+href="([^"]*)"\s+rel="canonical">/g
  // const canonicalMatch = canonicalRegex.exec(seo?.metaLinkContainer)
  // const canonicalUrl = canonicalMatch ? canonicalMatch[1] : null


  // OLD META FUNCTIONS
  // const getFilteredMetaTags = () => {
  //   const removedDescArr = metaTags.filter((item: any) => !item.includes(`name="description"`))
  //   const removedRobotsArr = removedDescArr.filter((item: any) => !item.includes(`name="robots"`))
  //   const removedOgTitleArr = removedRobotsArr.filter((item: any) => !item.includes(`property="og:title"`))
  //   const removedOgDescArr = removedOgTitleArr.filter((item: any) => !item.includes(`property="og:description"`))
  //   return removedOgDescArr.join('<')
  // }

  // const getFilteredCanonicalUrls = () => {
  //   const removedDescArr = metaLinks.filter((item: any) => !item.includes(`rel="canonical"`))
  //   return removedDescArr.join('<')
  // }

  // const getTitle = () => {
  //   if (!metaData?.length || !metaDataByLocation?.seoTitle) {
  //     return /<title>([^<]*)<\/title>/i.exec(seo?.metaTitleContainer)?.[1]
  //   } else {
  //     if (region === 'national' && metaData?.[0]?.seoTitle) {
  //       return metaData?.[0]?.seoTitle
  //     } else {
  //       return metaDataByLocation?.seoTitle
  //     }
  //   }
  // }

  // const getDescription = () => {
  //   if (!metaData?.length || !metaDataByLocation?.seoDescription) {
  //     return seoDescription
  //   } else {
  //     if (region === 'national' && metaData?.[0]?.seoDescription) {
  //       return metaData?.[0]?.seoDescription
  //     } else {
  //       return metaDataByLocation?.seoDescription
  //     }
  //   }
  // }

  // const getCanonicalUrl = () => {
  //   if (!metaData?.length || !metaDataByLocation?.canonicalUrl) {
  //     return canonicalUrl
  //   } else {
  //     if (region === 'national' && metaData?.[0]?.canonicalUrl) {
  //       return metaData?.[0]?.canonicalUrl
  //     } else {
  //       return metaDataByLocation?.canonicalUrl
  //     }
  //   }
  // }

  //NEW META CONSTANTS
  const seo = props.seo

  const metaDataByLocation = seo?.overrides?.find((item: any) => item?.location?.some((loc: any) => loc?.slug === location))
  const globalSeoData = props.globalSeoData
  // console.log('>>> GLOBAL SEO DATA', globalSeoData);

  // TODO: Need to replace bodyScript (GTM?)? with Global, ENV var:
  // const seoMatic = props.seomatic ?? null
  // const metaScripts = JSON.parse(seoMatic?.metaScriptContainer || null)
  // const bodyScript = metaScripts?.bodyScript

  //NEW META FUNCTIONS
  const getTitle = () => {
    const title = metaDataByLocation?.seoTitle ?? seo?.title ?? "Australian Building Co."
    const titleNoHtml = title?.replace(/<[^>]*>?/gm, '')
    const metaTitleSuffix = globalSeoData?.global__metaTitleSuffix ?? ''
    return `${titleNoHtml} ${metaTitleSuffix}`
  }

  const getDescription = () => {
    const entrySeoDescription = seo?.description && seo?.description.length > 0 ? seo?.description : null
    return metaDataByLocation?.seoDescription ?? entrySeoDescription ?? `For ${LOCATIONS_BY_REGION[region].longName} home builders you can trust, join the many property owners who have worked with Australian Building Co. to achieve their dream home.`
  }

  const getCanonical = () => {
    // console.log('metaDataByLocation?.canonicalUrl', metaDataByLocation?.canonicalUrl)
    return metaDataByLocation?.canonicalUrl ?? seo?.canonicalUrl
  }

  const getOpenGraphImage = () => {
    // 1,200 x 630 pixels
    const image =
    metaDataByLocation?.seoImage && metaDataByLocation?.seoImage.length > 0 ? metaDataByLocation?.seoImage[0] : null ||
    seo?.image && seo?.image.length > 0 ? seo.image[0] : null ||
    globalSeoData?.global__metaImage[0];

    if (!image) return null

    const imageSrc = getImgixUrl(image?.url, 1200, 630,'crop')
    const imagesArray = [{
      url: imageSrc,
      width: 1200,
      height: 630,
      alt: image?.title,
    }]
    // TODO: need to transform image?
    return imagesArray
  }

  const nextSeoProps = {
    title: getTitle(),
    description: getDescription(),
    canonical: getCanonical(),
    noindex: seo?.noindex,
    openGraph: {
      title: getTitle(),
      description: getDescription(),
      url: getCanonical(),
      images: getOpenGraphImage() ? getOpenGraphImage() : [],
      site_name: 'Australian Building Co.',
    },
    twitter: {
      handle: '@',
      creator: '@',
      title: getTitle(),
      description: getDescription(),
      image: getOpenGraphImage() ? getOpenGraphImage()[0].url : '',
      imageAlt: getOpenGraphImage() ? getOpenGraphImage()[0].alt : '',
      cardType: 'summary_large_image',
    },
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="facebook-domain-verification" content="rs2lg8yy795hrml075aeo0qu9ydv7y" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta property="og:type" content="website"/>
        <link rel="shortcut icon" href="https://assets-prod.abchomes.com.au/favicon.png" />
      </Head>
      {/* <NextSeo
        title={getTitle()}
        description={getDescription()}
        canonical={getCanonicalUrl()}
        additionalMetaTags={getSeodata(getFilteredMetaTags(), getFilteredCanonicalUrls(), metaData).metaTagsData}
        additionalLinkTags={getSeodata(getFilteredMetaTags(), getFilteredCanonicalUrls(), metaData).modifiedMetaLinkContainer}
        noindex={false}
      />
      */}
      {nextSeoProps && <NextSeo {...nextSeoProps} />}
      <TopHeader />
      <div className="sticky-wrap">
        <div className="sticky">
          <MainHeader activeTab={activeTab} />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            <h2>Old meta</h2>
            <div>
              <strong>Title</strong>: {getTitle()}{' '}
            </div>
            <div>
              <strong>Description</strong>: {getDescription()}{' '}
            </div>
            <div>
              <strong>Canonical</strong>: {getCanonicalUrl()}{' '}
            </div>
            <div><strong>Meta Tages</strong>: {getSeodata(getFilteredMetaTags(), getFilteredCanonicalUrls(), metaData).metaTagsData} </div>
            <div>
              <pre style={{ 'max-width': '800px', overflow: 'scroll' }}>{JSON.stringify(metaData, null, 2)}</pre>
              <pre style={{ 'max-width': '800px', overflow: 'scroll' }}>{JSON.stringify(seo, null, 2)}</pre>
            </div>
          </div>
          */}
          {/* <div style={{ flex: 1 }}>
            <h2>New meta</h2>
            <div>
              <p>
                <strong>Title</strong>: {getTitle()}
              </p>
              <p>
                <strong>Description</strong>: {getDescription()}
              </p>
              <p>
                <strong>Canonical</strong>: {getCanonical()}
              </p>
              <p>
                <strong>Image</strong>: {JSON.stringify(getOpenGraphImage())}
              </p>
              <pre>SEOPROPS: {JSON.stringify(nextSeoProps, null, 2)}</pre>
              <pre>SEO: {JSON.stringify(seo, null, 2)}</pre>
              <pre>OVERRIDES: {JSON.stringify(metaDataByLocation, null, 2)}</pre>
            </div>
          </div> */}
        {/* </div> */}
        <main>{children}</main>
        <MainFooter />
        <BottomFooter />
        {process.env.NEXT_PUBLIC_ENV !== 'prod' && (
          <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=bcblmjqgpxrgl5jkyo8ioa" async={true} />
        )}
        <script src="https://view.situsystems.com/v2/situsystems-situview.js"></script>
        <script src="https://cdn.situsystems.com/facade/api.js"></script>
        {/* <span dangerouslySetInnerHTML={{ __html: bodyScript }} /> */}
      </div>
    </>
  )
}

export default MainLayout;

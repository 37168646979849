/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react'
import styles from './main-header.module.scss'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { gql, useQuery } from '@apollo/client'
import OutsideClickHandler from 'react-outside-click-handler'
import { useStore } from 'hooks/locationContext'
import MobileNav from './mobile-nav'
import { ImgixImage } from 'components/ImgixImage'
import { reportError } from 'utils/monitoring'
import LoadingUI from 'components/loading'
import getUri from 'lib/getUri'
import { Header_Button_Entry, Header_Item_Entry, Header_Phone_Entry } from 'lib/generated/graphql-types'

interface HeaderEntries {
  entries: (Header_Button_Entry | Header_Item_Entry | Header_Phone_Entry)[] | null
}

export default function MainHeader({ activeTab }: any): JSX.Element {
  const [showMore, setShowMore] = useState(false)
  const [shoMobile, setShowMoreMobile] = useState(false)
  const [outsideClick, setOutsideClick] = useState(false)

  const currentLocation = useStore()[0]
  const { region, location } = currentLocation.initialLocation

  const QUERY_HEADER = gql`
    query getHeaderOne($siteHandle: [String] = "${region || 'vic'}"){
      entries(site: $siteHandle, section: "header"){
        ...on header_phone_Entry {
          phone
        }
        ...on header_item_Entry{
          id
          parent{
            id
          }
          title
          slug
          externalLink
          internalLink {
            id
            uri
          }
        }
      }
    }
  `
  const { loading, error, data } = useQuery<HeaderEntries>(QUERY_HEADER)
  const store = useStore()[0]

  const router = useRouter()
  const activeLinkClass = styles['--active']
  const entriesPhone = data?.entries as Header_Phone_Entry[]
  const entries = data?.entries as Header_Item_Entry[]

  // useKeyboardEvent();

  const hideTopMenu = () => {
    document.querySelector('.--top-header').classList.add('--d-none')
  }

  if (loading) return <LoadingUI />
  if (error) reportError(error)
  if (!data) return null

  const phoneNumber = (entriesPhone?.filter((entry) => entry?.phone) || [])[0]?.phone || ''

  const internalLinks = entries?.filter((entry) => entry?.parent && entry?.title?.trim().length)
  const generalLinks =
    entries
      ?.filter((entry) => !entry?.parent && entry?.title?.trim().length)
      .map((entry) => ({
        ...entry,
        internalLinks: internalLinks.filter((internalLink) => internalLink?.parent?.id === entry?.id),
      })) || []

  const linksUI = (links: any) =>
    links?.map((link: any, index: any) => {
      const url = link?.internalLink?.length ? `${getUri(store.initialLocation)}/${link.internalLink[0].uri}` : link?.externalLink
      const isExternal = url && /^https?:\/\//.test(url)
      return (
        <li key={'internalLink_' + index}>
          <Link href={url || '/'}>
            <a target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined}>
              {link?.title || ''}
            </a>
          </Link>
        </li>
      )
    })

  const navUI = generalLinks?.map((link, index) => {
    if (!link?.internalLinks?.length) {
      return (
        <li key={'generalLink_' + index}>
          <Link href={`${getUri(store.initialLocation)}/${link?.externalLink || '/'}`}>
            <a className={router.pathname.includes(link?.externalLink) || activeTab === link?.externalLink ? activeLinkClass : ''}>
              {link?.title || ''}
            </a>
          </Link>
        </li>
      )
    } else {
      return (
        <li key={'generalLink_' + index}>
          <a
            className="main-header--active"
            onClick={(e) => {
              if (!outsideClick) {
                setShowMore(!showMore)
              } else {
                setShowMore(outsideClick !== (e.currentTarget as any))
              }
              setOutsideClick(false)
            }}
          >
            {link?.title || ''}
          </a>

          {showMore && (
            <OutsideClickHandler
              onOutsideClick={(e) => {
                setOutsideClick(e.target as any)
                setShowMore(false)
              }}
            >
              <div className={styles['header__pop-up-menu'] + ' --pop-up-js'}>
                <ul>{linksUI(link?.internalLinks || [])}</ul>
              </div>
            </OutsideClickHandler>
          )}
        </li>
      )
    }
  })

  return (
    <header className={styles['header']}>
      <div className="container">
        <div className={styles['header__wrap']}>
          {/* only changed because of dodgy rerouting breaking pages */}
          <a href="/" className={styles['header__logo']}>
            <ImgixImage src="/matrix-field-preview/logoABC.svg" alt="Australian Building Co." width={154} height={40} />
          </a>
          <nav className={styles['header__nav']}>
            <ul>{navUI}</ul>
          </nav>
          <div className={styles['header__contact']}>
            <Link href={'tel:' + phoneNumber}>
              <a className={styles['header__contact-link']}>
                <span>{phoneNumber}</span>
              </a>
            </Link>
            <div
              className={styles['header__filter-button']}
              onClick={() => {
                setShowMoreMobile(!shoMobile)
                hideTopMenu()
              }}
            ></div>

            <Link href={`/${region}/${location}/contact-us`}>
              <a className={styles['header__contact-button'] + ' yellow-button'}>Contact us</a>
            </Link>
          </div>

          {shoMobile && <MobileNav closePopUp={setShowMoreMobile} />}
        </div>
      </div>
    </header>
  )
}

import { useRecoilState } from 'recoil'
import { LocationState } from 'lib/recoilStates/addedPriceState'

type LocationState = {
  region: string
  location: string
  title: string
  regionPriceDisclaimer: string
  crmRegion: string
  latitude: string
  longitude: string
}

type LocationStore = {
  initialLocation: LocationState | Partial<LocationState>
  changeRecently: number
  allLocations: LocationState[]
}

export const useStore = (): [LocationStore, (payload: LocationStore) => void] => {
  const [getLocationState, setLocationState] = useRecoilState(LocationState)
  const wrapSetLocationState = (payload: LocationStore) => {
    setLocationState(payload)
    try {
      localStorage.setItem('abc-location', JSON.stringify(payload.initialLocation))
    } catch (err) {}
  }

  return [getLocationState, wrapSetLocationState]
}
